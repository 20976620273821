









import { Component, Mixins, Vue } from 'vue-property-decorator'
import ModalBase from '@/components/molecules/ModalBase.vue'
import ModalBaseMethod from '@/components/molecules/ModalBaseMethod.vue'

@Component({
  components: {
    ModalBase,
  },
})
export default class ModalAdminGdlsCode extends Mixins(ModalBaseMethod) {
  private isProcessing = false

  private managerData: object[] = []

  public async loadManager(id: number) {
    if (this.isProcessing) return
    this.isProcessing = true

    const params = ['fields=nickname,gdlsCode']

    await Vue.prototype.$http.httpWithToken
      .get(`/branches/${id}/users?${params.join('&')}`)
      .then((res: any) => {
        if (res.data.users.length > 0) {
          this.managerData = res.data.users.map((user: { nickname: string; gdlsCode: string }, index: number) => {
            return {
              title: `教室長${index + 1}`,
              name: user.nickname,
              gdls: user.gdlsCode,
            }
          })
          this.show()
        } else {
          alert('教室長がいません。')
        }
      })
      .catch((error: any) => {
        if (error.response.data.status === 404) {
          alert('データが見つかりません。ページを更新してお確かめください。')
        }
      })
      .finally(() => {
        this.isProcessing = false
      })
  }
}
